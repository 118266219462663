import React from 'react'
import { IndicationAndSafety, PageHeader, PageSection } from '../../components';
import IndicationAndSafetyPage from '../../components/IndicationAndSafety/IndicationAndSafetyPage';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';

const Page = ({location}) => { 
  return (

    <DefaultLayout location={location} >
    <div data-template-name="template-d">
        {/* Begin Intro Column */}
        <TemplateColumn id="template-b-intro" maxWidth={824}>
            <PageHeader title="Earlier-Stage Indications" />
            {/* <PageSection title="KEYTRUDA® (pembrolizumab) is Indicated to Treat 6 Earlier-Stage Cancers" bgColor="white" centerTitle={true}>
                <PageIntro centered={true}>
                    <p>Explore efficacy and safety results and consider appropriate patients for earlier treatment with KEYTRUDA.</p>
                </PageIntro>
            </PageSection> */}
        </TemplateColumn>
        {/* End Intro Column */}

        {/* Begin Main Column */}
        <IndicationAndSafetyPage />

    </div>
</DefaultLayout>
  )
}

export default Page;
